// ============================================================================
// DocumentsManagerService
// -----------------------
// DocumentsManager module related services
// ============================================================================

// -------
// Imports
// -------
import { store } from '@/store'

import { NotImplementedYet, RequiredArguments, SetVueObservedProperty } from '@/helpers/methods'

import API from '@/apis/DocumentsManagerApi'

// ---------
// Internals
// ---------
const Private = {
	requests: {
		vendor: {
			document: {
				create: NotImplementedYet,
				read: function (vendorId, documentId, params = null) {
					RequiredArguments({ vendorId: vendorId, documentId: documentId })
					return API.vendor.document.read(vendorId, documentId, params)
				},
				update: function (vendorId, document, params = null, data = null) {
					RequiredArguments({ vendorId: vendorId, document: document })
					return API.vendor.document.update(vendorId, document.id, params, data)
				},
				delete: function (vendorId, document) {
					RequiredArguments({ vendorId: vendorId, document: document })
					return API.vendor.document.delete(vendorId, document.id)
				},
				author: {
					create: NotImplementedYet,
					read: function (vendorId, document, params = null) {
						RequiredArguments({ vendorId: vendorId, document: document })
						return API.vendor.document.author.read(vendorId, document.id, params)
					},
					update: NotImplementedYet,
					delete: NotImplementedYet
				}
			},
			folder: {
				create: function (vendorId, parent, data = null) {
					RequiredArguments({ vendorId: vendorId, parent: parent })
					return API.vendor.folder.create(vendorId, parent.id, data)
				},
				read: function (vendorId, folderIdentifier, params = null, doCancelPreviousRequest = true, doCancelAllOtherRequests = true) {
					RequiredArguments({ vendorId: vendorId, folderIdentifier: folderIdentifier })
					return API.vendor.folder.read(vendorId, folderIdentifier, params, doCancelPreviousRequest, doCancelAllOtherRequests)
				},
				update: function (vendorId, folderId, node, params = null, data = null) {
					RequiredArguments({ vendorId: vendorId, folderId: folderId, node: node })
					return API.vendor.folder.update(vendorId, folderId, node.id, params, data)
				},
				delete: function (vendorId, folder, params = null) {
					RequiredArguments({ vendorId: vendorId, folder: folder })
					return API.vendor.folder.delete(vendorId, folder.id, params)
				},
				document: {
					create: function (vendorId, parent, file) {
						RequiredArguments({ vendorId: vendorId, parent: parent, file: file })
						return API.vendor.folder.document.create(vendorId, parent, file)
					},
					read: function (vendorId, folderId, documentId, params = null) {
						RequiredArguments({ vendorId: vendorId, folderId: folderId, documentId: documentId })
						return API.vendor.folder.document.read(vendorId, folderId, documentId, params)
					},
					update: function (vendorId, folderId, node, params = null, data = null) {
						RequiredArguments({ vendorId: vendorId, folderId: folderId, node: node })
						return API.vendor.folder.document.update(vendorId, folderId, node.id, params, data)
					},
					delete: NotImplementedYet
				},
				folder: {
					create: NotImplementedYet,
					read: NotImplementedYet,
					update: function (vendorId, folderId, node, params = null, data = null) {
						RequiredArguments({ vendorId: vendorId, folderId: folderId, node: node })
						return API.vendor.folder.folder.update(vendorId, folderId, node.id, params, data)
					},
					delete: NotImplementedYet
				},
				nodes: {
					create: NotImplementedYet,
					read: function (vendorId, folder, params = null) {
						RequiredArguments({ vendorId: vendorId, folder: folder })
						return API.vendor.folder.nodes.read(vendorId, folder.id, params, true, true)
					},
					update: NotImplementedYet,
					delete: NotImplementedYet
				},
				documents: {
					create: NotImplementedYet,
					read: NotImplementedYet,
					update: NotImplementedYet,
					delete: NotImplementedYet
				},
				folders: {
					create: NotImplementedYet,
					read: NotImplementedYet,
					update: NotImplementedYet,
					delete: NotImplementedYet
				},
				catalogFolders: {
					create: function (vendorId, parent, catalogFolderId, params = null) {
						RequiredArguments({ vendorId: vendorId, parent: parent, catalogFolderId: catalogFolderId })
						return API.vendor.folder.catalogFolders.create(vendorId, parent.id, catalogFolderId, params)
					},
					read: function (vendorId, folderId, params = null) {
						RequiredArguments({ vendorId: vendorId, folderId: folderId })
						return API.vendor.folder.catalogFolders.read(vendorId, folderId, params)
					},
					update: NotImplementedYet,
					delete: NotImplementedYet
				}
			}
		}
	},
	service: {
		vendor: {
			document: {
				create: NotImplementedYet,
				read: function (vendorId, documentId, params = null) {
					RequiredArguments({ vendorId: vendorId, documentId: documentId })
					return Private.requests.vendor.document.read(vendorId, documentId, params).then(fetchedDocument => {
						return Private.store.vendor.structure.node.addOrUpdate(fetchedDocument).then(() => {
							return fetchedDocument
						})
					})
				},
				update: function (vendorId, document, params = null, data = null) {
					RequiredArguments({ vendorId: vendorId, document: document })
					return Private.requests.vendor.document.update(vendorId, document, params, data).then(updatedDocument => {
						return Private.store.vendor.structure.node.addOrUpdate(updatedDocument).then(() => {
							return updatedDocument
						})
					})
				},
				delete: function (vendorId, document, params = null) {
					RequiredArguments({ vendorId: vendorId, document: document })
					Private.store.vendor.structure.node.remove(document)
					const handleError = function (error) {
						Private.store.vendor.structure.node.addOrUpdate(document)
						throw error
					}
					return Private.requests.vendor.document.delete(vendorId, document)
						.catch(handleError)
						.then(() => {
							if (params && params.forceDelete) {
								return Private.requests.vendor.document.delete(vendorId, document)
									.catch(handleError)
							}
						})
				},
				author: {
					create: NotImplementedYet,
					read: function (vendorId, document) {
						RequiredArguments({ vendorId: vendorId, document: document })
						return Private.requests.vendor.document.author.read(vendorId, document)
					},
					load: function (vendorId, document) {
						RequiredArguments({ vendorId: vendorId, document: document })
						return Private.service.vendor.document.author.read(vendorId, document).then(author => {
							SetVueObservedProperty(document, 'author', author)
						})
					},
					update: NotImplementedYet,
					delete: NotImplementedYet
				}
			},
			folder: {
				create: function (vendorId, parent, data = null) {
					RequiredArguments({ vendorId: vendorId, parent: parent })
					return Private.requests.vendor.folder.create(vendorId, parent, data).then(createdFolder => {
						return Private.store.vendor.structure.node.addOrUpdate(createdFolder).then(() => {
							return Private.store.vendor.structure.node.get(createdFolder.id)
						})
					})
				},
				read: function (vendorId, folderIdentifier, parameters = null, doCancelPreviousRequest = true, doCancelAllOtherRequests = true) {
					RequiredArguments({ vendorId: vendorId, folderIdentifier: folderIdentifier })
					// eslint-disable-next-line sonarjs/cognitive-complexity
					return Private.store.vendor.structure.get().then(structure => {
						const isStructureLoaded = structure.fetched
						let requestParameters
						let identifier
						if (folderIdentifier == 'trash') {
							identifier = '-1'
						} else {
							identifier = folderIdentifier
						}
						if (isStructureLoaded || identifier == 'root' || parameters?.fetchingParent) {
							delete parameters.fetchingParent
							requestParameters = parameters
							const existingNode = Private.store.vendor.structure.node.get(folderIdentifier)
							if (existingNode && existingNode.id) {
								identifier = existingNode.id
							}
						} else {
							if (requestParameters && requestParameters.filters) {
								requestParameters.filters.with_content = true
							} else {
								requestParameters = {
									filters: {
										with_content: true
									}
								}
							}
						}
						return Private.requests.vendor.folder
							.read(vendorId, identifier, requestParameters, doCancelPreviousRequest, doCancelAllOtherRequests)
							.then(fetchedFolder => {
								let result = Promise.resolve()
								if (fetchedFolder 
									&& (
										store.state.documents.isFiltering
										|| vendorId !== Private.store.vendor.id.get()
									)
								) {
									result = Promise.resolve(fetchedFolder)
								} else if (fetchedFolder) {
									result = Private.store.vendor.structure.node
										.addOrUpdate(fetchedFolder)
										.then(() => {
											let subResult = Private.store.vendor.structure.node.get(fetchedFolder.id)
											if (!subResult) {
												subResult = fetchedFolder
											}
											return subResult
										})
										.then(retrievedFolder => {
											let subResult = Promise.resolve(retrievedFolder)
											if (
												retrievedFolder != null &&
												!retrievedFolder.is_root &&
												(!retrievedFolder.structure ||
													!retrievedFolder.structure.is_root ||
													!retrievedFolder.parent ||
													(retrievedFolder.parent.folders?.length ?? 0) == 0)
											) {
												const parentIdentifier = retrievedFolder.id > 0 && retrievedFolder.folder_id ? retrievedFolder.folder_id : 'root'
												const parentParameters = {
													fetchingParent: true,
													filters: {
														with_content: true
													},
													fields: ['counter', 'folders', 'folders.counter', 'folders.has_sub_folders', 'has_sub_folders']
												}
												subResult = Private.service.vendor.folder.read(vendorId, parentIdentifier, parentParameters).then(() => {
													let subSubResult
													if (!subSubResult) {
														subSubResult = retrievedFolder
													}
													if (!isStructureLoaded) {
														subSubResult = Private.service.vendor.folder.read(vendorId, retrievedFolder.id, parameters)
													} else {
														subSubResult = Private.store.vendor.structure.node.get(retrievedFolder.id)
														if (!subSubResult) {
															subSubResult = retrievedFolder
														}
													}
													return subSubResult
												})
											} else if (!isStructureLoaded && identifier != 'root') {
												subResult = Private.service.vendor.folder.read(vendorId, retrievedFolder.id, parameters)
											}
											return subResult
										})
								}
								return result
							})
					})
				},
				update: function (vendorId, folder, node, params = null, data = null) {
					RequiredArguments({ vendorId: vendorId, folder: folder, node: node })
					return Private.requests.vendor.folder.update(vendorId, folder, node, params, data).then(updatedFolder => {
						return Private.store.vendor.structure.node.addOrUpdate(updatedFolder).then(() => {
							return updatedFolder
						})
					})
				},
				delete: function (vendorId, folder) {
					RequiredArguments({ vendorId: vendorId, folder: folder })
					const children = folder.children
					if (folder.is_trash) {
						folder.clearChildren()
					} else {
						Private.store.vendor.structure.node.remove(folder)
					}
					return Private.requests.vendor.folder.delete(vendorId, folder).catch(error => {
						if (folder.is_trash) {
							children?.forEach(child => {
								Private.store.vendor.structure.node.addOrUpdate(child)
							})
						} else {
							Private.store.vendor.structure.node.addOrUpdate(folder)
						}
						throw error
					})
				},
				document: {
					create: function (vendorId, parent, file, params = null) {
						RequiredArguments(vendorId, parent, file)
						return Private.requests.vendor.folder.document.create(vendorId, parent, file, params).then(createdDocument => {
							return Private.store.vendor.structure.node.addOrUpdate(createdDocument).then(() => {
								return Private.store.vendor.structure.node.get(createdDocument.id)
							})
						})
					},
					read: function (vendorId, folderId, documentId, params = null) {
						RequiredArguments({ vendorId: vendorId, folderId: folderId, documentId: documentId })
						return Private.requests.vendor.folder.document.read(vendorId, folderId, documentId, params).then(fetchedFolderDocument => {
							return Private.store.vendor.structure.node.addOrUpdate(fetchedFolderDocument).then(() => {
								return Private.store.vendor.structure.node.get(fetchedFolderDocument.id)
							})
						})
					},
					update: function (vendorId, folderId, node, params = null, data = null) {
						RequiredArguments({ vendorId: vendorId, folderId: folderId, node: node })
						return Private.requests.vendor.folder.document.update(vendorId, folderId, node, params, data).then(updatedFolderDocument => {
							return Private.store.vendor.structure.node.addOrUpdate(updatedFolderDocument).then(() => {
								return Private.store.vendor.structure.node.get(updatedFolderDocument.id)
							})
						})
					},
					delete: NotImplementedYet
				},
				folder: {
					create: NotImplementedYet,
					read: NotImplementedYet,
					update: function (vendorId, folderId, node, params = null, data = null) {
						RequiredArguments({ vendorId: vendorId, folderId: folderId, node: node })
						return Private.requests.vendor.folder.folder.update(vendorId, folderId, node, params, data).then(updatedFolderFolder => {
							return Private.store.vendor.structure.node.addOrUpdate(updatedFolderFolder).then(() => {
								return Private.store.vendor.structure.node.get(updatedFolderFolder.id)
							})
						})
					},
					delete: NotImplementedYet
				},
				folders: {
					create: NotImplementedYet,
					read: NotImplementedYet,
					update: NotImplementedYet,
					delete: NotImplementedYet
				},
				documents: {
					create: NotImplementedYet,
					read: NotImplementedYet,
					update: NotImplementedYet,
					delete: NotImplementedYet
				},
				nodes: {
					create: NotImplementedYet,
					read: function (vendorId, folder, params = null) {
						RequiredArguments({ vendorId: vendorId, folder: folder })
						return Private.requests.vendor.folder.nodes.read(vendorId, folder, params).then(fetchedNodes => {
							fetchedNodes?.forEach(node => Private.store.vendor.structure.node.addOrUpdate(node))
							const fetchedNodesId = fetchedNodes?.map(fetchedNode => fetchedNode.id)
							folder.children
								?.filter(existingFolderChild => !fetchedNodesId.includes(existingFolderChild.id))
								.forEach(node => Private.store.vendor.structure.node.remove(node))
							folder.fetched = true
							return Private.store.vendor.structure.node.get(folder.id)
						})
					},
					load: function (vendorId, folder, params = null) {
						return Private.requests.vendor.folder.nodes.read(vendorId, folder, params).then(fetchedNodes => {
							return fetchedNodes
						})
					},
					update: NotImplementedYet,
					delete: NotImplementedYet
				},
				catalogFolders: {
					create: function (vendorId, parent, catalogFolderId, params = null) {
						RequiredArguments({ vendorId: vendorId, parent: parent, catalogFolderId: catalogFolderId })
						return Private.requests.vendor.folder.catalogFolders.create(vendorId, parent, catalogFolderId, params).then(createdFolder => {
							return Private.store.vendor.structure.node.addOrUpdate(createdFolder).then(() => {
								return Private.service.vendor.folder.nodes.read(vendorId, createdFolder)
							})
						})
					},
					read: function (vendorId, folderId, data = null) {
						RequiredArguments({ vendorId: vendorId, folderId: folderId })
						return Private.requests.vendor.folder.catalogFolders.read(vendorId, folderId, data)
					},
					update: NotImplementedYet,
					delete: NotImplementedYet
				}
			}
		}
	},
	store: {
		vendor: {
			id: {
				get: function () {
					const structure = store.state.documents.treeStructure
					let vendorId = structure?.vendor_id
					if (!vendorId) {
						vendorId = store.state.company.selected?.id
					}
					
					return vendorId
				}
			},
			structure: {
				get: function () {
					return Promise.resolve(store.getters['documents/structure'])
				},
				node: {
					addOrUpdate: function (node) {
						RequiredArguments({ node: node })
						return store.dispatch('documents/addOrUpdateNodeInStructure', node)
					},
					get: function (nodeIdentifier, isFiltering) {
						RequiredArguments({ nodeIdentifier: nodeIdentifier })
						return store.getters['documents/findNodeInStructure'](nodeIdentifier, isFiltering)
					},
					remove: function (node) {
						RequiredArguments({ node: node })
						return store.dispatch('documents/removeNodeFromStructure', node)
					}
				},
				folder: {
					markAsOpened: function (folder) {
						RequiredArguments({ folder: folder })
						return store.dispatch('documents/markFolderAsOpened', folder)
					},
					markIdAsOpened: function (folderId) {
						RequiredArguments({ folderId: folderId })
						return store.dispatch('documents/markFolderIdAsOpened', folderId)
					},
					markAsClosed: function (folder) {
						RequiredArguments({ folder: folder })
						return store.dispatch('documents/markFolderAsClosed', folder)
					},
					markIdAsClosed: function (folderId) {
						RequiredArguments({ folderId: folderId })
						return store.dispatch('documents/markFolderIdAsClosed', folderId)
					}
				}
			}
		},
		setIsFiltering: function (isFiltering) {
			RequiredArguments({ isFiltering: isFiltering })
			return store.dispatch('documents/setIsFiltering', isFiltering)
		},
		setValidatedFilter: function (validated) {
			return store.dispatch('documents/setValidatedFilter', validated)
		},
		setUnvalidatedFilter: function (unvalidated) {
			return store.dispatch('documents/setUnvalidatedFilter', unvalidated)
		},
		setSearchFilename: function (searchedName) {
			return store.dispatch('documents/setSearchFilename', searchedName)
		},
		setSearchResults: function (searchResults) {
			return store.dispatch('documents/setSearchResults', searchResults)
		},
		reset: function () {
			return store.dispatch('documents/reset')
		},
		resetSearchFilter: function () {
			return store.dispatch('documents/resetSearchFilter')
		},
		resetFilter: function () {
			return store.dispatch('documents/resetFilter')
		}
	}
}

// -------
// Exports
// -------
export default {
	createDocument: Private.service.vendor.document.create,
	getDocument: Private.service.vendor.document.read,
	updateDocument: Private.service.vendor.document.update,
	deleteDocument: Private.service.vendor.document.delete,

	createDocumentAuthor: Private.service.vendor.document.author.create,
	getDocumentAuthor: Private.service.vendor.document.author.read,
	loadDocumentAuthor: Private.service.vendor.document.author.load,
	updateDocumentAuthor: Private.service.vendor.document.author.update,
	deleteDocumentAuthor: Private.service.vendor.document.author.delete,

	createFolder: Private.service.vendor.folder.create,
	getFolder: Private.service.vendor.folder.read,
	updateFolder: Private.service.vendor.folder.update,
	deleteFolder: Private.service.vendor.folder.delete,

	createFolderDocument: Private.service.vendor.folder.document.create,
	getFolderDocument: Private.service.vendor.folder.document.read,
	loadFolderDocument: Private.service.vendor.folder.document.load,
	updateFolderDocument: Private.service.vendor.folder.document.update,
	deleteFolderDocument: Private.service.vendor.folder.document.delete,

	createFolderFolder: Private.service.vendor.folder.folder.create,
	getFolderFolder: Private.service.vendor.folder.folder.read,
	updateFolderFolder: Private.service.vendor.folder.folder.update,
	deleteFolderFolder: Private.service.vendor.folder.folder.delete,

	createFolderNodes: Private.service.vendor.folder.nodes.create,
	getFolderNodes: Private.service.vendor.folder.nodes.read,
	loadFolderNodes: Private.service.vendor.folder.nodes.load,
	updateFolderNodes: Private.service.vendor.folder.nodes.update,
	deleteFolderNodes: Private.service.vendor.folder.nodes.delete,

	createFolderDocuments: Private.service.vendor.folder.documents.create,
	getFolderDocuments: Private.service.vendor.folder.documents.read,
	updateFolderDocuments: Private.service.vendor.folder.documents.update,
	deleteFolderDocuments: Private.service.vendor.folder.documents.delete,

	createFolderFolders: Private.service.vendor.folder.folders.create,
	getFolderFolders: Private.service.vendor.folder.folders.read,
	updateFolderFolders: Private.service.vendor.folder.folders.update,
	deleteFolderFolders: Private.service.vendor.folder.folders.delete,

	getFolderCatalogFolders: Private.service.vendor.folder.catalogFolders.read,
	createFolderCatalogFolders: Private.service.vendor.folder.catalogFolders.create,

	findNodeInStructure: Private.store.vendor.structure.node.get,
	addOrUpdateNode: Private.store.vendor.structure.node.addOrUpdate,
	markFolderAsOpened: Private.store.vendor.structure.folder.markAsOpened,
	markFolderIdAsOpened: Private.store.vendor.structure.folder.markIdAsOpened,
	markFolderAsClosed: Private.store.vendor.structure.folder.markAsClosed,
	markFolderIdAsClosed: Private.store.vendor.structure.folder.markIdAsClosed,
	setIsFiltering: Private.store.setIsFiltering,
	setSearchFilename: Private.store.setSearchFilename,
	setSearchResults: Private.store.setSearchResults,
	setValidatedFilter: Private.store.setValidatedFilter,
	setUnvalidatedFilter: Private.store.setUnvalidatedFilter,
	reset: Private.store.reset,
	resetSearchFilter: Private.store.resetSearchFilter,
	resetFilter: Private.store.resetFilter
}
